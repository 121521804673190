import React from "react"
import Seo from "../components/Seo/Seo"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import G from "../images/groenhout_g.png"
import Container from "../components/Container/Container"
import GravityForm from "../components/GravityForm"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import image from "../images/team-foto-compleet.png"

export default function StartGroenhout() {
  const seo = useStaticQuery(
    graphql`
      query StartPage {
        wpPage(slug: { eq: "goed-van-start-met-groenhout" }) {
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    `
  )

  const image = seo.wpPage.featuredImage?.node.mediaItemUrl

  return (
    <>
      <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc} />
      <SmallHeroNew
        title="Goed van start met Groenhout"
        desc=""
        overlay={true}
        overlayImage={G}
        image={image !== null ? image : Image}
      ></SmallHeroNew>
      <Container styles={`py-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">
            Jouw Eerste Huis, Zorgeloos Gekocht!
          </h2>
          <p className="mb-0">
          Ben je een starter op de woningmarkt en weet je niet waar je moet beginnen? Wij helpen je!
          Met onze <strong>Startersdeal</strong> krijg je deskundige begeleiding van een ervaren <strong>aankoopmakelaar</strong> én
          een <strong>onafhankelijke hypotheekadviseur</strong>. Je weet precies wat je kunt lenen, profiteert van de
          voordelen van een aankoopmakelaar en staat daardoor sterker in je aankoopproces.
          </p>
        </div>
      </Container>
      <Container styles={`pb-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">Een deal speciaal voor starters.</h2>
          <div>
            <p>Als experts op de woningmarkt merken we dat starters het nog altijd moeilijk hebben op de woningmarkt, hierom hebben we een deal bedacht speciaal voor de starters.</p>
            <h3 className="text-blue text-3xl">Maar wat houdt deze deal nou precies in?</h3>
            <ul className="list-disc">
              <li>
              Een unieke samenwerking tussen uw makelaar en financieel adviseur;
              </li>
              <li>Je krijgt complete aankoopbegeleiding door een van onze Groenhout Makelaars op
              een ‘No Cure, No Pay’ basis;</li>
              <li>
              Hypotheekadvies en bemiddeling bij alle geldverstrekkers;
              </li>
              <li>Onbeperkt aantal bezichtigingen met de makelaar</li>
            </ul>
          </div>
        </div>
      </Container>
      <Container styles={`pb-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">Waarom kiezen voor de Startersdeal</h2>
          <div>
            <p>Doordat onze makelaars en de financieel adviseur nauw samenwerken kunnen we makkelijk schakelen en zorgen we ervoor dat jij goed voorbereid het aankooptraject in gaat en de beste begeleiding hebt.</p>
            <ul className="list-disc">
              <li>
              Begeleiding van A tot Z door middel van uw <strong>persoonlijk aankoopplan</strong>.
              </li>
              <li>Het beste hypotheekadvies bij een <strong>onafhankelijke</strong> hypotheekadviseur</li>
              <li>Kostenbesparing; een <strong>vaste prijs</strong>, geen hogere prijs betalen bij de koop van een
              duurder huis!</li>
              <li><strong>Tijdsbesparing</strong> doordat wij alles voor je uit handen neemt.</li>
            </ul>
          </div>
        </div>
      </Container>
      <Container styles={`py-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">
            Kosten van de startersdeal
          </h2>
          <p className="">
            Deze all-in deal kost € 3999,- inclusief BTW.
          </p>
        </div>
      </Container>
      <Container styles={`pb-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">Meer weten?</h2>
          <p className="">
            Neem contact met ons op voor meer informatie over deze deal!
          </p>
          <div className="border bg-white border-light p-5">
            <GravityForm id={"6"} colorClass={`bg-light`} />
          </div>
        </div>
      </Container>
    </>
  )
}
